import { useState, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import Image from 'next/image';
import { ValidationError } from 'yup';

import Review from '@assets/sidebar/review.svg';
import CheckMark from '@assets/sidebar/check-mark.svg';
import { Button } from '@common';
import ReviewPage from '@components/page-steps/ReviewPage';
import SignPage from '@components/page-steps/SignPage';
import SuccessPage from '@components/page-steps/SuccessPage';
import { yupResolver } from '@hookform/resolvers/yup';
import useLocalStorage from '@hooks/useLocalStorage';
import { NavigationContextProvider } from '@hooks/useNavigationContext';
import useToast from '@hooks/useToast';
import { applicationValidatorSchema, signSchema, validatorSchema } from '@utils/validators';
import { mergeValues } from '@utils/helperFunctions';

import {
    BankFields,
    BusinessFields,
    ProcessingFields,
    WizardStep,
    WizardProps,
    EquipmentSetUpFields,
    AllFields, AddendumFields
} from './types';
import { WizardContext } from './useWizardContext';
import WizardPage from './WizardPage';
import WizardSwitch from './WizardSwitch';

const defaultValues = {
    [BusinessFields.DBANAME]: '',
    [BusinessFields.LEGALNAME]: '',
    [BusinessFields.B_ADD1]: '',
    [BusinessFields.B_ADD2]: '',
    [BusinessFields.B_CITY]: '',
    [BusinessFields.B_ZIPCODE]: '',
    [BusinessFields.IS_LEGAL_SAME_AS_PYSICAL]: 'yes' as const,
    [BusinessFields.L_ADD1]: '',
    [BusinessFields.L_ADD2]: '',
    [BusinessFields.L_CITY]: '',
    [BusinessFields.L_ZIPCODE]: '',
    [BusinessFields.B_PHONE]: '',
    [BusinessFields.B_EMAIL]: '',
    [BusinessFields.TIN]: '',
    [BusinessFields.B_WEBSITE]: '',
    [BusinessFields.NO_OF_LOCATIONS]: 1,
    [ProcessingFields.MARKETING_WEBSITE]: '',
    [ProcessingFields.MARKETING_FILE]: '',
    [ProcessingFields.TXN_COMPLETE_PERIOD]: '',
    [ProcessingFields.RECURRING_BILLING]: 'no',
    [ProcessingFields.BILLING_PERIOD]: '',
    [ProcessingFields.DB_COLLECTING_CC_NUMBERS]: 'no',
    [ProcessingFields.PCI_COMPLIANT]: '',
    [ProcessingFields.DAYS_TO_RETURN_CANCEL]: '',
    [ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]: 'no',
    [ProcessingFields.DELIVERY_IMMEDIATE]: '0',
    [ProcessingFields.DELIVERY_1_3_DAYS]: '0',
    [ProcessingFields.DELIVERY_4_7_DAYS]: '0',
    [ProcessingFields.DELIVERY_8_14_DAYS]: '0',
    [ProcessingFields.DELIVERY_15_30_DAYS]: '0',
    [ProcessingFields.DELIVERY_OVER_30_DAYS]: '0',
    [ProcessingFields.DOES_CUS_SIGN_CONTRACT]: 'no',
    [ProcessingFields.CONTRACT_FILE]: '',
    [ProcessingFields.HOW_CUSTOMER_ORDER]: '',
    [ProcessingFields.VENDOR_PUR_ADDRESS]: '',
    [ProcessingFields.IS_CNP_REQUIRED]: 'yes',
    [ProcessingFields.SERVICE_FOR_CC_HOLDERS]: '',
    'people': [],
    [BankFields.P_BANK_ROUTING_NUMBER]: '',
    [BankFields.P_BANK_ACCOUNT_NUMBER]: '',
    [BankFields.WAIVE_PERSONAL_GUARANTEE]: 'no',
    [BankFields.GUARANTEE_STATEMENT1]: '',
    [BankFields.GUARANTEE_STATEMENT2]: '',
    [BankFields.GUARANTEE_STATEMENT3]: '',
    [BankFields.GUARANTEE_B_SHEET]: '',
    [BankFields.GUARANTEE_PL_STATEMENT]: '',
    [BankFields.MASKED_P_BANK_ACCOUNT_NUMBER]: '',
    [BankFields.P_BANK_ACC_FILE]: '',
    [BankFields.EXTRA_FILES]: [],
    [EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL]: 'yes' as const,
    [EquipmentSetUpFields.SHIPPING_ADD1]: '',
    [EquipmentSetUpFields.SHIPPING_ADD2]: '',
    [EquipmentSetUpFields.SHIPPING_CITY]: '',
    [EquipmentSetUpFields.SHIPPING_ZIPCODE]: '',
    [EquipmentSetUpFields.SHIPPING_PHONE]: '',
    [EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]: '',
    [EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]: '',
    [EquipmentSetUpFields.INSTALL_CONTACT_PHONE]: '',
    [EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]: '',
    [EquipmentSetUpFields.UTILIZE_TIPS]: '',
    [EquipmentSetUpFields.UTILIZE_CARD_STORAGE]: '',
    [EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]: '',
    [EquipmentSetUpFields.ETHERNET_AVAILABLE]: '',
    [EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]: '',
    [EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]: '',
    [EquipmentSetUpFields.POS_ACCESS]: '',
    [EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]: '',
    [EquipmentSetUpFields.NO_OF_COMPUTERS]: '',
    [EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]: '',
    [EquipmentSetUpFields.GIFT_CARD_PROVIDER]: '',
    [EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]: '',
    [EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]: '',
    [EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]: '',
    [EquipmentSetUpFields.NO_OF_WORK_STATIONS]: '',
    [EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]: '',
    [EquipmentSetUpFields.REPORTING_TRAINING]: '',
    [EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]: '',
    [EquipmentSetUpFields.PROCESSING_DATE]: '',
    [AddendumFields.DEBIT_CARD_BILLING]: 'Debit Card on Month-End Billing',
};

const Wizard = ({
    id,
    children,
    hasEIServerOrCloud = false,
    hasEmergepay = false,
    isOpportunity = false,
    isApplication = false,
    isSalesUser = false,
    isSigner = true,
    isSubmitted = false,
    pdfComponent = () => <div />,
    onComplete = async () => { },
    onFileUpload = async () => { throw new Error('onFileUpload not implemented') },
    onFileRemoval = async () => undefined,
    onOwnerAdd = async () => { throw new Error('onOwnerAdd not implemented') },
    onOwnerRemoval = async () => undefined,
    steps: validatedSteps = [],
    values: loadedValues = null,
}: WizardProps) => {
    const [showBackToReview, setShowBackToReview] = useState(false);
    const [isSaving, setIsSaving] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isReviewDisabled, SetReviewDisabled] = useState(false);
    const [initialPage, rememberActivePage] = useLocalStorage<string>(`${id || 'default'}:activeIndex`);
    const [currentPage, setCurrentPage] = useState(+initialPage || 0);
    const [completedSteps, setCompletedSteps] = useState(0);
    const validationContext = useMemo(() => ({ hasEIServerOrCloud, hasEmergepay, isOpportunity, optional: !isSigner }), [hasEIServerOrCloud, hasEmergepay, isOpportunity, isSigner]);
    const { hideRequiredWarningToast } = useToast();

    const form = useForm<AllFields>({
        mode: 'all',
        // todo: after beta, we could explore assigning {values: values, defaultValues: defaultValues} I do not want to break things now since this works
        values: mergeValues(defaultValues, loadedValues),
        resolver: yupResolver(isApplication ? applicationValidatorSchema : validatorSchema),
        context: validationContext,
    });

    const handleSignSubmit = useCallback(async () => {
        const values = form.getValues();
        try {
            if (isSigner) {
                signSchema.validateSync(values, { abortEarly: false, context: validationContext });
            }
            await onComplete(values);
            form.clearErrors();
            return { hasCompleted: true, hasWarning: false, redirect: true };
        } catch (error) {
            datadogLogs.logger.error('Error submitting', error);
            if (ValidationError.isError(error)) {
                const errors = error.inner.reduce((acc, err) => {
                    if (err.path) {
                        acc[err.path] = err.message;
                    }
                    return acc;
                }, {} as Record<string, string>);
                // LY: I can't find a way to set priority order on the validations so we do this manually. Otherwise we could remove { abortEarly: false }
                if (errors.keyedInSignature) {
                    form.setError('keyedInSignature', { message: errors.keyedInSignature });
                } else if (errors.timestamp) {
                    form.setError('timestamp', { message: errors.timestamp });
                } else if (errors.ipAddress) {
                    form.setError('ipAddress', { message: errors.ipAddress });
                }
                return { hasCompleted: false, hasWarning: true, redirect: false };
            }
            throw error;
        }
    }, [form, isSigner, onComplete, validationContext]);

    const handleReviewNext = useCallback(async () => {
        const schema = isApplication ? applicationValidatorSchema : validatorSchema;
        const values = form.getValues();
        const valid = await schema.isValid(values, { context: validationContext });
        return {
            hasCompleted: valid,
            hasWarning: !valid,
            redirect: valid,
        };
    }, [form, isApplication, validationContext]);

    const handlePartialSubmit = useCallback(async () => {
        const valid = await handleReviewNext();
        if (valid.hasCompleted) {
            return handleSignSubmit();
        }
        return {
            hasCompleted: false,
            hasWarning: true,
            redirect: false,
        }
    }, [handleReviewNext, handleSignSubmit]);

    const opportunityStage = form.getValues('opportunityStage')

    const steps: WizardStep[] = useMemo(() => {
        const mappedSteps = opportunityStage !== 'Application' && !isSubmitted && isOpportunity ? [] :
            validatedSteps.map((step): WizardStep => ({
                name: step.name,
                title: step.title,
                onNext: async () => {
                    const valid = await step.schema.isValid(form.getValues(), {
                        context: validationContext,
                    });
                    form.trigger(step.fields as any); // FIXME(LY) narrow this type
                    return { hasCompleted: valid, hasWarning: !valid, redirect: true };
                },
                schema: step.schema,
                children: step.children,
            })).concat({
                   name: 'review-page',
                   title: 'Review',
                   footer: {
                       nextButtonDisabled: isReviewDisabled
                   },
                   onNext: isSigner ? handleReviewNext : undefined,
                   children: <ReviewPage />,
               })

        return [
            ...mappedSteps,
            {
                name: 'sign-page',
                footer: {
                    buttonSteps: ['back', <Button onClick={isSigner ? handleSignSubmit : handlePartialSubmit} key="continue" variant="secondary" className="mx-2">
                        Submit
                    </Button>]
                },
                title: 'Sign',
                children: <SignPage/>,
            },
            {
                name: 'successPage',
                footer: { enabled: false },
                title: 'Application Complete',
                children: <SuccessPage />,
            }
        ];
    }, [validatedSteps, isSigner, handleReviewNext, handlePartialSubmit, handleSignSubmit, form, isReviewDisabled, opportunityStage, isSubmitted, validationContext, isOpportunity]);

    useEffect(() => {
        if (currentPage >= steps.length) {
            setCurrentPage(0);
        }
        if (currentPage === steps.length - 2) { // if the user is on sign page
            const values = form.getValues();
            validatorSchema.isValid(values, { context: validationContext }).then(result => {
                if (!result) { // if the schema validation is invalid
                   setCurrentPage(steps.length - 3) // go to review page
                }
            });
        }
    }, [currentPage, steps, form, validationContext]);
    const getCurrentStep = useCallback(() => steps[currentPage], [currentPage, steps]);
    // Force jump to success page on reload after submission
    if (isSubmitted && !isSalesUser && (currentPage !== steps.length - 1)) {
        setCurrentPage(steps.length - 1); // Success page
    }

    // Reset after moving from Review page
    useEffect(() => {
        setShowBackToReview(false);
    }, [currentPage]);


    const handleValidateReviewPage = useCallback(async () => {
        const step = getCurrentStep();

        if (step?.name === 'review-page') {
            const values = form.getValues();
            const isValid= await validatorSchema.isValid(values, { context: validationContext });
            if (!isValid) {
                SetReviewDisabled(true);
            }
        } else {
            SetReviewDisabled(false);
            hideRequiredWarningToast();
        }
    }, [form, getCurrentStep, hideRequiredWarningToast, validationContext])

    // Validate Review Page for warning
    useEffect(() => {
        handleValidateReviewPage();
    }, [handleValidateReviewPage, currentPage]);

    const context = useMemo(() => ({
        form,
        steps,
        completedSteps,
        isSigner,
        isSaving,
        isSubmitted,
        id,
        showBackToReview,
        isOpportunity,
        isApplication,
        loading,
        hasEIServerOrCloud,
        hasEmergepay,
        onComplete,
        setIsSaving,
        getCurrentStep,
        onFileRemoval,
        onFileUpload,
        onOwnerAdd,
        onOwnerRemoval,
        pdfComponent,
        setShowBackToReview,
        setLoading,
    }), [completedSteps, form, getCurrentStep, id, isApplication, isOpportunity, isSigner, isSaving, isSubmitted, loading, hasEIServerOrCloud, hasEmergepay, onComplete, onFileRemoval, onFileUpload, onOwnerAdd, onOwnerRemoval, pdfComponent, showBackToReview, steps]);

    return <WizardContext.Provider value={context}>
        <FormProvider {...form}>
            <NavigationContextProvider
                currentPage={currentPage}
                setCompletedSteps={setCompletedSteps}
                setCurrentPage={page => {
                    setCurrentPage(page);
                    rememberActivePage(`${page}`);
                }}
                beforeJump={() => {
                    const currentStep = getCurrentStep();
                    return (currentStep?.onNext || (() => ({ hasCompleted: false, hasWarning: false, redirect: true })))();
                }}
                beforeForward={() => {
                    const handleOnNext = getCurrentStep().onNext || (() => ({
                        hasWarning: false,
                        hasCompleted: false,
                        redirect: true,
                    }));
                    return handleOnNext();
                }}
                pages={steps.slice(0, -1).map((step, index) => ({ // slice to hide success page
                    title: step.title,
                    // eslint-disable-next-line no-nested-ternary
                    progressIcon: step.name === 'review-page'
                        ? <Image src={Review} alt='check' />
                        : step.name === 'sign-page'
                            ? <Image src={CheckMark} alt='check' />
                            : index + 1,
                    disableJump: step.name === 'sign-page' || loading,
                }))}
                initialState={+initialPage && loadedValues
                    ? steps.reduce((acc, { schema }, i) => {
                        if (+initialPage > i && schema) {
                            const isValid = schema.isValidSync(loadedValues, {context: validationContext});
                            if (isValid) {
                                acc.completed.add(i);
                            } else {
                                acc.warning.add(i);
                            }
                        }
                        return acc;
                    }, { completed: new Set<number>(), warning: new Set<number>() })
                    : { completed: new Set<number>(), warning: new Set<number>() }
                }
            >
                {children}
            </NavigationContextProvider>
        </FormProvider>
    </WizardContext.Provider>;
};

export default Wizard;
export {
    WizardPage as Page,
    WizardSwitch as Switch,
}
